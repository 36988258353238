<template>
    <b-field>
        <b-input icon="square-edit-outline"
                 ref="input"
                 :placeholder="label"
                 @keyup.native.enter="submit"
                 @keyup.native.esc="cancel"
                 @input="input"
                 v-model="val">
        </b-input>
        <div class="control control-addon">
            <button class="button has-text-success" @click="submit">
                <b-icon icon="check-circle"></b-icon>
            </button>
            <button class="button has-text-danger" @click="cancel">
                <b-icon icon="close-circle"></b-icon>
            </button>
        </div>
    </b-field>
</template>

<script>
    export default {
        name: "weInput",
        props: {
            label: {type: String, required: true},
            value: {type: String, required: true}
        },
        data: function () {
            return {
                val: this.$props.value
            }
        },
        methods: {
            input: function () {
                this.$emit("input", this.val)
            },
            submit: function(e) {
                this.$emit('submit', e);
            },
            cancel: function(e) {
                this.$emit('cancel', e);
            },
            focus: async function() {
                this.$refs.input.focus();
            }
        }
    }
</script>