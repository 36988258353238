<template>
    <div class="todo" :pk="todo.pk" :class="{done}" @click="activate">
        <header class="todo-header">
            <div class="columns">
                <w-editable class="column todo-topic" :enabled="active" :model="todo" field="topic" label="Thema">
                    {{ todo.topic}}
                </w-editable>
                <w-editable class="column todo-title" :enabled="active" :model="todo" field="title" label="Titel">
                    {{ todo.title}} <DueLabel :date="todo.due"></DueLabel>
                </w-editable>
            </div>
            <a class="card-header-icon" role="button" @click="toggle">
                <b-icon :icon="(active)?'chevron-up':'chevron-down'"/>
            </a>
        </header>
        <div class="todo-body" v-if="active">
            <div class="columns is-vcentered">
                <div class="column todo-due-label has-text-weight-bold">
                    Fällig
                </div>
                <w-editable type="date" class="column todo-due" label="Due"
                            :enabled="active" :model="todo" field="due">
                    {{ format_date(todo.due) }}
                </w-editable>
                <div class="column todo-planned-label has-text-weight-bold">
                    Geplant
                </div>
                <w-editable type="date" class="column todo-planned" label="Plan day"
                            :enabled="active" :model="todo" field="plan_day">
                    {{ format_date(todo.plan_day) }}
                </w-editable>
            </div>
            <div class="columns is-vcentered">
                <div class="column todo-next-label has-text-weight-bold">
                    Nächster Schritt
                </div>
                <w-editable class="column todo-next" :enabled="active" :model="todo" field="next_step"
                            label="Next Step">
                    {{ todo.next_step }}
                </w-editable>
            </div>
            <w-editable class="fill-height" type="textarea" :model="todo" field="text" label="Text" :enabled="active">
                <div class="content" v-html="todo.as_html"></div>
            </w-editable>
        </div>
        <footer class="todo-footer" v-if="active">
            <div class="column">
                <b-dropdown aria-role="menu" class="is-left is-up">
                    <div class="button is-white" slot="trigger" aria-label="more options" role="button">
                        <b-icon icon="menu-up"/>
                    </div>
                    <b-dropdown-item aria-role="menuitem" class="has-text-danger" @click="onDelete">
                        <b-icon icon="delete" size="is-small"></b-icon>
                        delete
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="column has-text-right">
                <button @click="toggleDone" class="button is-primary is-right">
                    <span v-if="!todo.done"><b-icon icon="check" size="is-small"/> Done! </span>
                    <span v-else>undone</span>
                </button>
            </div>
        </footer>
    </div>
</template>

<script>
    import {wEditable} from '../../plugins/editable';
    import DueLabel from './DueLabel'
    import {scrollIntoMiddle} from '../../scroll';

    const MAGIC_EMPTY_DATE = '9999-12-31';
    const DATE_OPTIONS = {
            weekday: "long",
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
    };

    export default {
        name: "TodoItem",
        props: ["todo", "active"],
        components: {
            wEditable,
            DueLabel
        },
        computed: {
            done: function () {
                return (this.todo.closed_at != null);
            }
        },
        mounted() {
            if (this.$props.active) {
                scrollIntoMiddle(this.$el);
            }
        },
        updated() {
            if (this.$props.active) {
                scrollIntoMiddle(this.$el);
            }
        },
        methods: {
            activate: async function () {
                if (!this.$props.active) {
                    await this.$store.commit('setActiveItem', {item: this.$props.todo});
                    await this.$nextTick;
                    scrollIntoMiddle(this.$el);
                }
            },
            toggle: function (e) {
                if (this.$props.active) {
                    this.$editable.deactivate();
                    this.$store.commit('setActiveItem', {item: null});
                } else {
                    this.activate(e);
                }
                e.stopPropagation();
            },
            toggleDone: function () {
                this.$store.dispatch('toggle_done', {model: this.$props.todo})
            },
            onDelete: function () {
                this.$store.dispatch('delete', {model: this.$props.todo});
            },
            format_date: function (date) {
                if (date === MAGIC_EMPTY_DATE) {
                    return "-"
                } else {
                    const d = new Date(date);
                    return d.toLocaleDateString(navigator.language, DATE_OPTIONS);
                }
            },
            format_date_relative: function(date) {
                const d = new Date(date), today = new Date();
                const fmt = new Intl.RelativeTimeFormat(navigator.language, { numeric: 'auto' });

                let delta = Math.floor((d-today) / 86400000) + 1;
                let unit = 'day';

                if (Math.abs(delta) > 365) {
                    delta = Math.floor(delta/365);
                    unit = 'years';
                } else if (Math.abs(delta) > 8) {
                    delta = Math.floor(delta / 7);
                    unit = 'weeks';
                }
                return fmt.format(delta, unit);
            }
        }
    }
</script>

<style scoped>

</style>