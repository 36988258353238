<template>
    <form class="login" @submit="login">
        <div class="card" style="width:300px;">
            <section class="modal-card-body">
                <b-field label="User / Email">
                    <b-input v-model="user"
                             type="input"
                             placeholder="User / Email"
                             required>
                    </b-input>
                </b-field>

                <b-field label="Password">
                    <b-input v-model="password"
                             type="password"
                             password-reveal
                             placeholder="Password"
                             required>
                    </b-input>
                </b-field>
            </section>
            <footer class="card-foot">
                <button @click="login" class="button is-primary">Login</button>
            </footer>
        </div>
    </form>
</template>

<script>
    export default {
        name: "LoginForm",
        data: function () {
            return {
                user: "",
                password: ""
            }
        },
        methods: {
            login: function (e) {
                e.preventDefault();
                this.$store.dispatch('auth/login', {user: this.user, password: this.password});
            }
        }
    }
</script>

<style>
    .login {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .login .card-foot {
        padding: 1em;
        text-align: center;
    }
</style>