import {Session} from "../api";

const auth = {
    namespaced: true,
    state: {
        loggedIn: false,
        token: null
    },
    mutations: {
        setAuthToken(state, {loggedIn, token}) {
            state.loggedIn = loggedIn;
            state.token = token;
        },
    },
    actions: {
        login({commit}, data) {
            Session.login(data.user, data.password).then((result) => {
                commit('setAuthToken', {loggedIn: true, token: result.key})
            }).catch((error) => {
                commit('setAuthToken', {loggedIn: false, token: null})
            })
        },
        logout({commit}) {
            Session.logout().then(() => {
                commit('setAuthToken', {loggedIn: false, token: null})
            })
        },
    }
};

export default auth;