import Client from './client'

const login_url = '/api-auth/login/';
const logout_url = '/api-auth/logout/';

/**
 * implements a very basic, token-based authentification using
 * django-rest-framework tokens and django-rest-auth views,
 * see https://django-rest-auth.readthedocs.io/
 */
export default class Session {

    static async login(username, password) {
        return await Client.fetch(login_url, {username, password}, 'POST')
    }

    static async logout() {
        return await Client.fetch(logout_url, null,'POST')
    }

}
