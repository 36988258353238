
const MAGIC_EMPTY_DATE = '9999-12-31';

const DATE_OPTIONS_LONG = {
    weekday: "long",
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
};

const DATE_OPTIONS_SHORT = {
    // weekday: "",
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
};

/**
 * format a date in the local form
 * @param date  date in string format
 * @param long  use long date format
 * @param empty placeholder for empty date
 * @returns {string} formatted date
 */
function formatDate(date, long=false, empty="") {
    if (date === MAGIC_EMPTY_DATE) {
        return empty
    } else {
        const d = new Date(date);
        return d.toLocaleDateString(navigator.language, (long) ? DATE_OPTIONS_LONG : DATE_OPTIONS_SHORT);
    }
}

function formatDateSortable(date) {
    if (date) {
        const d = new Date(date),
              f = d.toISOString().slice(0, 10);
        return f;
    } else {
        return '9999-12-31'
    }
}

/**
 * calculate dates between date and reference date
 *
 * @param date
 * @param ref (default: today)
 * @returns {number} days between date and ref_date
 */
function getDaysBetween(date, ref = null) {
    const ref_date = ref || new Date(),
        d = new Date(date);
    return Math.floor((d - ref_date) / 86400000) + 1;
}

/**
 * format a relative date into string
 *
 * @param days number of days
 * @returns {string}
 */
function formatDateRelative(days) {
    const fmt = new Intl.RelativeTimeFormat(navigator.language, {numeric: 'auto'});
    let unit = 'day';
    let delta = days;

    if (Math.abs(delta) > 365) {
        delta = Math.floor(delta / 365);
        unit = 'years';
    } else if (Math.abs(delta) > 8) {
        delta = Math.floor(delta / 7);
        unit = 'weeks';
    }
    return fmt.format(delta, unit);
}


export {
    MAGIC_EMPTY_DATE,
    formatDate,
    formatDateSortable,
    formatDateRelative,
    getDaysBetween
}