
import wEditable from './editable'

const Events = {
    ACTIVATE: 'edActivate',
    DEACTIVATE: 'edDeactivate',
    UPDATE: 'edUpdate'
};

const plugin = {
    install: function (Vue) {
        Vue.prototype.$editable = new Vue({
            data: function() {
                return {
                    editor: null
                }
            },
            methods: {
                async activate(model, field) {
                    if (this.editor) {
                        this.$emit(Events.UPDATE, this.editor.$props.model, this.editor.$props.field, this.editor.val);
                        this.editor.deactivate();
                        this.editor = null;
                    }
                    await this.$emit(Events.ACTIVATE, model, field);
                },
                activated(editor) {
                    // add editing class
                    document.documentElement.classList.add('editor-active');
                    this.editor = editor;
                },
                async deactivate(model = null, field = null) {
                    if (model && field) {
                        // ask editor to deactivate
                        await this.$emit(Events.DEACTIVATE, model, field);
                    } else {
                        // ask currently open editor to deactivate
                        if (this.editor) {
                            this.editor.deactivate();
                        }
                    }
                    // in all cases, remove class & set current editor to null
                    document.documentElement.classList.remove('editor-active');
                    this.editor = null;
                },
                deactivated(editor) {
                    // remove editing class
                    document.documentElement.classList.remove('editor-active');
                    if (editor !== this.editor) {
                        // console.warn("deactivating inactive editor", editor)
                    }
                    this.editor = null;
                },
                async submit(editor) {
                    await this.$emit(Events.UPDATE, editor.$props.model, editor.$props.field, editor.val);
                    editor.deactivate();
                },
                async cancel(editor) {
                    editor.deactivate();
                }
            }
        });
    }
};

export default plugin;

export {
    Events,
    wEditable
}