function scrollIntoMiddle(el, container_selector = ".scroll-container") {

    if (!el) return; // can't scroll if there's no element...

    const container = el.closest(container_selector),     // scroll container
        elOffset = el.offsetTop - container.offsetTop;  // offset of el's top, relative to container

    setTimeout(() => {
        window.scrollTo(0, elOffset)
    }, 0);


    /*
    const container = el.closest(container_selector),     // scroll container
          containerHeight = container.clientHeight,       // visible height of container
          elHeight = el.clientHeight,                     // height of dest element
          elOffset = el.offsetTop - container.offsetTop;  // offset of el's top, relative to container

    // container.scrollTop = elOffset;
    // if we would set container.scrollTop to offsetTop, the element
    // would be at the top always. But we want it centered, so we
    // distribute the remaining space evenly (e.g. scroll down *less*)

    const remainder = containerHeight - elHeight;
    let scrollPos = 0;
    if (remainder > 0) {
        scrollPos = Math.floor(elOffset - (remainder / 2));
    } else {
        scrollPos = elOffset;
    }

    console.log({elHeight, containerHeight, elOffset, remainder, scrollPos});

    */
}

function scrollToTop() {
    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 0);
}


export {
    scrollIntoMiddle,
    scrollToTop
}
