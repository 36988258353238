<template>
    <b-tag v-if="date!=='9999-12-31'" :type="type" class="is-pulled-right">{{ relative }}</b-tag>
</template>

<script>
    export default {
        name: "DueLabel",
        props: ["date"],
        computed: {
            type: function () {
                if (this.delta < 1) {
                    return 'is-danger'
                }
                if (this.delta < 3) {
                    return 'is-warning'
                }
                return 'is-success'
            },
            delta: function () {
              const d = new Date(this.date), today = new Date();
              return Math.floor((d - today) / 86400000) + 1;
            },
            relative: function () {
                const fmt = new Intl.RelativeTimeFormat(navigator.language, {numeric: 'auto'});
                let delta = this.delta;
                let unit = 'day';

                if (Math.abs(delta) > 365) {
                    delta = Math.floor(delta / 365);
                    unit = 'years';
                } else if (Math.abs(delta) > 8) {
                    delta = Math.floor(delta / 7);
                    unit = 'weeks';
                }
                return fmt.format(delta, unit);
            }
        }
    }
</script>
