const layout = {
    namespaced: true,
    state: {
        sidebarVisible: (window.innerWidth > 768) // initially hidden on mobile
    },
    mutations: {
        toggleSidebar(state, {visible}) {
            state.sidebarVisible = visible;
        },
    }
};

export default layout;
