<template>
    <nav class="todo-nav app-nav is-vcentered">
        <div class="todo-search">
            <b-input @input="setQuery"
                     icon="magnify"
                     ref="search"
                     placeholder="Search...">
            </b-input>
        </div>
        <div class="todo-add">
            <button @click="addTodo" class="button is-primary is-pulled-right">
                <b-icon icon="note-plus"></b-icon>
                <span>Add</span>
            </button>
        </div>
    </nav>
</template>

<script>
    import {mapState, mapGetters, mapMutations} from 'vuex'

    export default {
        name: "TodoTabs",
        computed: {
            ...mapState([
                'todos',
                'filters'
            ]),
            ...mapGetters([
                'topics',
                'filtered'
            ])
        },
        methods: {
            addTodo: async function () {
                const data = {
                    'topic': this.filters.topic || "",
                    'title': "",
                    'text': ""
                };
                const newTodo = await this.$store.dispatch('createTodo', data);
                if (!newTodo.topic) {
                    this.$editable.activate(newTodo, 'topic');
                } else {
                    this.$editable.activate(newTodo, 'title');
                }
            },
            setTopicFilter: function (topic) {
                this.setFilter({topic});
            },
            setQuery: function (query) {
                this.setFilter({query});
            },
            ...mapMutations(['setFilter'])
        }
    }
</script>

<style scoped>

</style>