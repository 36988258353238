<template>
    <div id="app" :class="{'side-nav-hidden': !layout.sidebarVisible}">
        <!--<div id="ruler" style="position: fixed; left: 0; right: 0; top: 50%; height: 1px; background-color: black;" ></div>-->
        <!--<TopNav />-->
        <TodoApp v-if="auth.loggedIn"/>
        <LoginForm v-else />
    </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import LoginForm from './components/LoginForm'
    import TodoApp from './components/todo/TodoApp'

    export default {
        name: 'app',
        props: ['authToken'],
        created() {
            const at = this.$props.authToken;
            if (at) {
                this.$store.commit('auth/setAuthToken', {loggedIn: true, token: at});
            }
        },
        computed: {
            ...mapState(['auth', 'layout']),
            ...mapGetters(['filtered'])
        },
        components: {
            TodoApp,
            LoginForm
        }
    }
</script>

<style>

</style>
