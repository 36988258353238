// string formatting utilities

/**
 * return the value, or a replacement if empty
 *
 * @param val   The Value
 * @param replacement The Replacement
 * @returns {*}
 */
function empty(val, replacement) {
    return (val) ? val : replacement;
}

/**
 * capitalize the word
 *
 * @param {string}   word
 * @returns {string} capitalized word
 */
function capitalize(word) {
    // can't capitalize if not string
    if (typeof word !== 'string') return word
    return word.charAt(0).toUpperCase() + word.slice(1)
}


export {
    empty,
    capitalize
}