import Vue from 'vue'
import Vuex from 'vuex'
import {ToDo} from '../api/'
import groupBy from 'lodash/groupBy'
import auth from './auth'
import layout from './layout'
import {formatDateSortable} from '../date'


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

function array_replace(haystack, needle, replacement) {
    return haystack.map((x) => (x === needle) ? replacement : x)
}


function filter_done(done, todo) {
    return done === null ||
        done === true && todo.done ||
        done === false && !todo.done
}

function filter_topic(topic, todo) {
    return topic === null ||
        todo.topic === topic
}

function filter_query(query, todo) {
    return query === null ||
        (todo.title.toLowerCase().indexOf(query) !== -1) ||
        (todo.text.toLowerCase().indexOf(query) !== -1);
}

export default new Vuex.Store({
    modules: {
        auth,
        layout
    },
    state: {
        // full list of TODOs
        todos: [],
        // activeItem is the active, expanded item
        activeItem: null,
        // active filters
        filters: {
            done: false,
            topic: null,
            query: null
        }
    },
    getters: {
        topics: state => {
            return new Set(state.todos.map(todo => todo.topic))
        },
        counts: state => {
            const topic = state.filters.topic;
            let c = {
                _total: {
                    open: 0,
                    done: 0
                }
            };
            state.todos.forEach((todo) => {
                const _fld = (todo.done) ? 'done' : 'open';
                if (!c.hasOwnProperty(todo.topic)) {
                    c[todo.topic] = {open: 0, done: 0}
                }
                c[todo.topic][_fld] ++;
                c._total[_fld] ++;
            });
            if (topic!==null && c.hasOwnProperty(topic)) {
                c._current = c[topic];
            } else {
                c._current = c["_total"];
            }
            return c;
        },
        filtered: state => {
            const {done, topic, query} = state.filters;
            const _query = (query) ? query.toLowerCase() : null;
            const filtered = state.todos
                .filter(todo =>
                    filter_done(done, todo) &&
                    filter_topic(topic, todo) &&
                    filter_query(_query, todo))
            return filtered
        },
        grouped: (state, getters) => {
            const field = (state.filters.done) ? 'closed_at' : 'plan_day';
            return groupBy(getters.filtered, (x) => {return formatDateSortable(x[field])})
        },
    },
    mutations: {
        setTodos(state, todos) {
            state.todos = todos;
        },
        addTodo(state, todo) {
            // ToDo: whether to add at beginning or end, depends on the
            //       order
            state.todos.push(todo);
        },
        replaceTodo(state, {model, replacement}) {
            state.todos = array_replace(state.todos, model, replacement)
        },
        deleteTodo(state, {model}) {
            state.todos = state.todos.filter((x) => x !== model)
        },
        setFilter(state, {query, topic, done}) {
            state.filters = {
                query: (query !== undefined) ? query : state.filters.query,
                topic: (topic !== undefined) ? topic : state.filters.topic,
                done: (done !== undefined) ? done : state.filters.done
            }
        },
        setActiveItem(state, {item}) {
            state.activeItem = item;
        }
    },
    actions: {
        getTodos({commit}) {
            ToDo.objects.all().then((todos) => {
                commit('setTodos', todos)
            })
        },
        createTodo({commit}, data) {
            return ToDo.objects.create(data).then((newTodo) => {
                commit('addTodo', newTodo);
                commit('setActiveItem', {item: newTodo});
                return newTodo;
            })
        },
        modelUpdate({commit}, {model, data}) {
            return model.update(data).then((replacement) => {
                commit('replaceTodo', {model, replacement});
                commit('setActiveItem', {item: replacement});
                return replacement;
            })
        },
        toggle_done({commit}, {model}) {
            model.toggle_done().then((replacement) => {
                commit('replaceTodo', {model, replacement});
                commit('setActiveItem', {item: null});
            })
        },
        delete({commit, getters, state}, {model}) {
            model.delete().then(() => {
                commit('deleteTodo', {model});
                if (!getters.topics.has(state.filters.topic)) {
                    commit('setFilter', {topic: null})
                }
                commit('setActiveItem', {item: null});
            })
        }
    },
    strict: debug,
    plugins: []
})