<template>
    <div class="editable" :class="{active}">
        <!-- editor -->
        <component v-if="active"
                   :is="getEditor()"
                   :label="label"
                   v-model="val"
                   @submit="submit"
                   @cancel="cancel"
                   ref="editor"></component>
        <!-- show the original content -->
        <div v-else class="field-display" @click="requestActivate">
            <slot ref="slot"></slot>
        </div>
    </div>
</template>

<script>

    import editors from './editor';
    import {scrollIntoMiddle} from '../../scroll';

    // polyfill for Event.path/event.composedPath, see
    // https://stackoverflow.com/a/46093727
    function eventPath(evt) {
        let path = (evt.composedPath && evt.composedPath()) || evt.path,
            target = evt.target;

        if (path != null) {
            // Safari doesn't include Window, but it should.
            return (path.indexOf(window) < 0) ? path.concat(window) : path;
        }

        if (target === window) {
            return [window];
        }

        function getParents(node, memo) {
            memo = memo || [];
            var parentNode = node.parentNode;

            if (!parentNode) {
                return memo;
            } else {
                return getParents(parentNode, memo.concat(parentNode));
            }
        }

        return [target].concat(getParents(target), window);
    }


    export default {
        name: "wEditable",
        props: {
            type: {
                type: String,
                default: 'input'
            },
            model: {
                type: Object,
                required: true
            },
            field: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            enabled: {
                type: Boolean,
                default: true
            }
        },
        data: function () {
            return {
                active: false,
                val: this.$props.model[this.$props.field]
            }
        },
        mounted() {
            this.$editable.$on('edActivate', this.onActivateRequest);
            this.$editable.$on('edDeactivate', this.onDeactivateRequest);
        },
        beforeDestroy() {
            if (this.active) {
                this.$editable.deactivated(this);
            }
            this.$editable.$off('edActivate', this.onActivateRequest);
            this.$editable.$off('edDeactivate', this.onDeactivateRequest);

            if (typeof window !== 'undefined') {
                document.removeEventListener('click', this.clickedOutside)
            }
        },
        methods: {
            getEditor: function () {
                return editors[this.$props.type];
            },

            onActivateRequest: function (model, field) {
                if (this.$props.model === model && this.$props.field === field) {
                    this.activate()
                }
            },

            onDeactivateRequest: function (model, field) {
                if (this.$props.model === model && this.$props.field === field) {
                    this.deactivate()
                }
            },

            requestActivate: function (e) {
                if (this.enabled && !this.active) {
                    this.$editable.activate(this.$props.model, this.$props.field, this);
                    e.preventDefault();
                    e.stopPropagation();
                }
            },

            activate: async function () {
                this.active = true;
                this.$editable.activated(this);
                await this.$nextTick();
                await this.$refs.editor.focus();
                scrollIntoMiddle(this.$el.closest('.todo'));
                // add event handler for outside click
                if (typeof window !== 'undefined') {
                    document.addEventListener('click', this.clickedOutside)
                }
            },
            deactivate: function () {
                this.active = false;
                this.$editable.deactivated(this);
                // remove event listener
                if (typeof window !== 'undefined') {
                    document.removeEventListener('click', this.clickedOutside)
                }
            },
            cancel: function () {
                this.val = this.$props.model[this.$props.field];
                this.$editable.cancel(this);
            },
            submit: function () {
                this.$editable.submit(this);
            },
            clickedOutside: function (e) {
                // if we are not active or changing state, ignore
                if (!this.active) return;
                // click into active editor itself does not cancel either
                if (this.$refs.editor && eventPath(e).indexOf(this.$refs.editor.$el) !== -1) return;
                if (this.$refs.slot && eventPath(e).indexOf(this.$refs.slot.$el) !== -1) return;
                // otherwise, store the changed value (tests showed that
                // cancel is not the right thing to do here)
                this.$editable.submit(this);
            }
        }
    }
</script>
