import Vue from 'vue'
import Buefy from 'buefy'

import App from './App.vue'
import store from './store'
import Editable from './plugins/editable/'

import "./theme/app.scss";

Vue.use(Buefy);
Vue.use(Editable);

Vue.config.productionTip = false;


export const load_components = () => {
    /*
    const todos = [];


    // note: we need to collect elements before mounting vue on it, as this
    // will delete the found element...
    for (const el of document.getElementsByTagName('w0-todo')) {
        todos.push({...el.dataset});
    }
    */
    const app = document.getElementById('app');
    const token = app.dataset.authToken;

    new Vue({
        el: '#app',
        store,
        render: h => h(App, {props: {authToken: token}}),
    }); //.$mount('#app');

    /*
    for (const el of els) {
        const props = {todo: {...el.dataset}};
        const vm = new Vue({render: h => h(Todo, {props: props})}).$mount(el);
    }
    */
};

/*
new Vue({
  render: h => h(App),
}).$mount('#app');
*/

window.load_components = load_components;