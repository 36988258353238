<template>
    <div class="app-wrapper">
        <div class="side-nav">
            <SideMenu/>
        </div>
        <div class="app-container todo-app" @keyup.left="navLeft" @keyup.right="navRight">
            <todo-nav/>
            <div class="scroll-container">
                <div class="card-list">
                    <div :key="day" v-for="day in Object.keys(grouped).sort()">
                        <h1 :id="day" class="title">{{ formatDate(day) }}</h1>
                        <todo-item v-for="todo in grouped[day]"
                                   :active="activeItem===todo"
                                   :key="todo.url"
                                   :todo="todo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TodoItem from './TodoItem'
    import TodoNav from './TodoNav'
    import SideMenu from './SideMenu'
    import {mapState, mapGetters} from 'vuex'
    import {getDaysBetween, formatDate, formatDateRelative} from "../../date";
    import {capitalize} from "../../strformat";



    export default {
        name: "TodoApp",
        components: {
            SideMenu,
            TodoItem,
            TodoNav,
        },
        computed: {
            ...mapState(['todos', 'activeItem', 'filters']),
            ...mapGetters(['filtered', 'grouped'])
        },
        created() {
            this.$editable.$on('edUpdate', this.fieldUpdate);
            this.$store.dispatch('getTodos');
        },
        beforeDestroy() {
            this.$editable.$off('edUpdate', this.fieldUpdate);
        },
        methods: {
            navLeft: function () {
                // console.log("navLeft");
            },
            navRight: function () {
                // console.log("navRight");
            },
            fieldUpdate: async function (model, field, value) {
                const data = {};
                data[field] = value;
                const updatedTodo = await this.$store.dispatch('modelUpdate', {model, data});
                // if topic has been changed and a topic filter is set, switch to new topic
                if (field === 'topic' && this.filters.topic && updatedTodo.topic != this.filters.topic) {
                    this.$store.commit('setFilter', {topic: updatedTodo.topic})
                }
            },
            formatDate: function (date) {
                // are we in history display?
                const in_history = (this.filters.done === true)

                if (in_history) {
                    // history always shows absolute dates
                    return formatDate(date, false, "Ohne Datum")
                } else {
                    // plan shows relative dates for +- 2 days,
                    // absolute else
                    const delta = getDaysBetween(date)
                    if (Math.abs(delta) < 3) {
                        return capitalize(formatDateRelative(delta))
                    } else {
                        return formatDate(date, true, "Ohne Datum")
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>