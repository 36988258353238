<template>
    <b-field>
        <b-input icon="calendar-today"
                 type="date"
                 ref="input"
                 :placeholder="label"
                 @keyup.native.enter="submit"
                 @keyup.native.esc="cancel"
                 @input="input"
                 v-model="val" />
        <div class="control control-addon">
            <button class="button has-text-success" @click="submit">
                <b-icon icon="check-circle"></b-icon>
            </button>
            <button class="button has-text-danger" @click="cancel">
                <b-icon icon="close-circle"></b-icon>
            </button>
        </div>
    </b-field>
</template>

<script>
    const MAGIC_EMPTY_DATE = '9999-12-31';

    export default {
        name: "weDatepicker",
        props: {
            label: {type: String, required: true},
            value: {type: String, required: true}
        },
        data: function () {
            return {
                val: (this.$props.value === MAGIC_EMPTY_DATE) ? "" : this.$props.value
            }
        },
        methods: {
            input: function (e) {
                this.$emit("input", (this.val) ? this.val : MAGIC_EMPTY_DATE)
            },
            submit: function (e) {
                this.$emit('submit', e)
            },
            cancel: function (e) {
                this.val = this.$props.value;
                this.$emit('cancel', e)
            },
            focus: async function () {
                this.$refs.input.focus();
            }
        }
    }
</script>