import Client from './client'

/**
 * Manager class. Managers manage model collections and are
 * somewhat similar to Django managers, but not nearly as complex.
 *
 * Each model MUST have at least one static manager, but additional
 * managers might be added if desired
 *
 * class MyModel extends Model {
 *     //...
 * }
 * // the default manager
 * MyModel.objects = new Manager(MyModel, 'some/path/')
 * // a second manager which only lists active objects
 * MyModel.active = new Manager(MyModel, 'some/path/', {active: true})
 *
 */
export default class Manager {
    /**
     * Creates a manager.
     *
     * @param {Model} model_class
     * @param {string} list_url
     * @param {Object} query
     */
    constructor (model_class, list_url, query=null) {
        this.modelClass = model_class;
        this.listUrl = list_url;
        this.query = query;
    }

    /**
     * return all objects
     */
    async all() {

        // todo: query...
        const modelClass = this.modelClass;
        return Client.fetch(this.listUrl).then((results) => {
            return results.map((result) => {
                return new modelClass(result);
            })
        });
    }

    async filter() {

    }

    async create(data) {
        const modelClass = this.modelClass;
        return Client.fetch(this.listUrl, data, 'POST').then((result) => {
            return new modelClass(result);
        });
    }

}
