
import Client from './client'

export default class Model {
    constructor(data) {
        Object.keys(data).map((k) => {
            this[k] = data[k];
        })
    }

    /**
     * performs a DRF custom action
     *
     * @param {string} name of the action
     * @param {Object} data parameters
     * @param {string} method (e.g. GET, POST, ...)
     * @returns {Promise}
     */
    action(name, data, method) {
        const url = this.url + name +  '/';
        return Client.fetch(url, data, method)
    }

    /**
     * updates this instance from data, returning a new model instance
     *
     * @param {Object} data
     * @returns {Promise} new model instance
     */
    update(data) {
        return Client.fetch(this.url, data, 'PATCH').then((response) => {
            return new this.constructor(response)
        });
    }

    /**
     * deletes this instance
     *
     * @returns {Promise} (without content)
     */
    delete() {
        return Client.fetch(this.url, null, 'DELETE');
    }
}
