<template>
    <aside class="menu">
        <p class="menu-top side-nav-header">
            <a class="side-nav-logo" href="/"><img src="/static/w0.svg" style="height: 40px;"></a>
            <a class="side-nav-toggle" @click="toggleSidebar">
                <b-icon :icon="collapseIcon" size="is-large"></b-icon>
            </a>
        </p>
        <div class="side-nav-content">
            <p class="menu-label">
                Aufgaben
            </p>
            <ul class="menu-list">
                <li><a @click="showActive(true)" :class="{'is-active': !filters.done}">
                    Offen <span class="count is-danger">{{ counts._current.open}}</span>
                </a>
                </li>
                <li>
                    <a @click="showActive(false)" :class="{'is-active': filters.done}">
                        Erledigt <span class="count">{{ counts._current.done}}</span>
                    </a>
                </li>
            </ul>
            <p class="menu-label">
                Themen
            </p>
            <ul class="menu-list">
                <li>
                    <a :class="{'is-active': filters.topic==null}"
                       @click="clearTopic">(alle) <span class="count">{{ counts._total.open}}</span>
                    </a>
                </li>
                <li v-for="topic in topics" :key="topic">
                    <a :data-topic="topic"
                       :class="{'is-active': topic==filters.topic}"
                       @click="setTopic">
                        {{ empty(topic, "(ohne)") }} <span class="count">{{ counts[topic].open}}</span>
                    </a>
                </li>
            </ul>
        </div>

    </aside>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';
    import {empty} from "../../strformat";
    import {scrollIntoMiddle, scrollToTop} from "../../scroll";
    import {formatDateSortable} from "../../date";

    export default {
        name: "SideMenu",
        computed: {
            ...mapState(['filters', 'layout']),
            ...mapGetters(['topics', 'counts']),
            collapseIcon: function () {
                return (this.layout.sidebarVisible) ? 'chevron-double-up' : 'chevron-double-down';
            }
        },
        methods: {
            setTopic: function (e) {
                this.$store.commit('setFilter', {topic: e.currentTarget.dataset.topic});
                scrollToTop();
            },
            clearTopic: function() {
                this.$store.commit('setFilter', {topic: null});
                scrollToTop();
            },
            showActive: function (active) {
                this.$store.commit('setFilter', {done: !active});
                // if history is showing, scroll to current date
                this.$nextTick(() => {
                    if (!active) {
                        scrollIntoMiddle(document.getElementById(formatDateSortable(new Date())))
                    } else {
                        scrollToTop();
                    }
                });
            },
            toggleSidebar: function () {
                this.$store.commit('layout/toggleSidebar', {visible: !this.layout.sidebarVisible})
            },
            empty
        }
    }
</script>

<style scoped>

</style>