import Cookie from "js-cookie";

/**
 * A light wrapper around the fetch API
 */
export default class Client {

    static async fetch(path, data=null, method='GET') {
        const url = path;
        const options = {
            method: method,
            headers: {
                'X-CSRFToken': Cookie.get('csrftoken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        if (data) {
            options['body'] = JSON.stringify(data);
        }
        let responsedata = {}
        const response = await fetch(url, options);
        if (response.headers.get('content-length') > 0) {
            responsedata = await response.json()
        }
        if ([200, 201, 204].indexOf(response.status) !== -1) {
            return responsedata
        } else {
            throw responsedata
        }
    }
}

