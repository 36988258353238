
import Client from './client'
import Session from './auth'
import Manager from './manager'
import Model from './model'

class ToDo extends Model {

    get done() {
        return this.closed_at != null
    }

    toggle_done() {
        return this.action('toggle_done', null, 'POST')
                   .then((response) => {
            return new this.constructor(response);
        })
    }
}
ToDo.objects = new Manager(ToDo, '/api/todos/');

export {
    Client,
    Session,
    ToDo
}

